import React, { FC } from 'react';

import './service.css';

interface ServiceProps {
	title: string;
	description: string;
	image: 'ants' | 'fleas' | 'mosquitos' | 'roaches' | 'rodents' | 'spiders';
	imageSide?: 'right' | 'left';
}

export const Service: FC<ServiceProps> = (props) => {
	const { title, description, image, imageSide } = props;

	return (
		<div
			className="home-section-wrapper"
			style={{ flexDirection: imageSide === 'right' ? 'row' : 'row-reverse' }}>
			<div className="home-content04">
				<div className="home-header12">
					<h2 className="home-heading10">{title}</h2>
					<p className="home-capton">{description}</p>
				</div>
			</div>
			<img alt={image} src={`/Services/${image}.png`} className="home-image15" />
		</div>
	);
};
