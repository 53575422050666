import React, { FC } from "react";
import { Guarantee } from "sections/home-hero/guarantee";
import { HeroList } from "sections/home-hero/hero-list";
import { phoneNumber, zeroBugs } from "utils/consts";

export const Hero: FC = () => {
  return (
    <div className="home-main">
      <div className="home-content">
        <img className="dead-bug" src="/bug.png" />
        <h1 className="secondary-header" style={{ marginBottom: 45 }}>
          ZERO TOXINS • ZERO POISONS • ZERO BUGS
        </h1>

        <p className="hero-body">
          {`${zeroBugs} harnessed the power of nature's own natural defenses against insects.`.toLocaleUpperCase()}
        </p>
        <p className="hero-body">
          {`${zeroBugs} is the smartest, safest, natural way to eliminate the bugs you see – and the bugs you can't see.`.toLocaleUpperCase()}
        </p>
        <HeroList />
        <p className="hero-body">
          {`Our Name is Your Guarantee.`.toLocaleUpperCase()}
        </p>
        <div className="home-page-guarantee">
          <Guarantee />
        </div>
      </div>
    </div>
  );
};
