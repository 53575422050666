import { Licensee } from "components";
import React, { FC } from "react";
import { zeroBugs } from "utils/consts";

export const MeetLicensees: FC = () => {
  return (
    <section className="home-meet">
      <div className="home-heading18">
        <h2 className="home-text81">
          Meet our Licensees & Certified Applicators
        </h2>
        <p className="home-text82">
          Our licensees – the driving force behind the revolutionary {zeroBugs}{" "}
          organization.
        </p>
      </div>
      <div className="home-list1">
        <div className="home-doctors">
          <Licensee
            heading="Ally Pest & Termite"
            imageSrc="/Licensees/ally.jpeg"
            objectFit="contain"
          />
          <Licensee
            heading="Mayco Pest & Termite"
            imageSrc="/Licensees/mayco.jpg"
            objectFit="contain"
          />
          {/* <Licensee heading="Dallas" imageSrc="/Licensees/dallas.jpeg" /> */}
        </div>
      </div>
    </section>
  );
};
