import React, { FC, useState } from "react";
import { Menu } from "components";
import { Logo } from "shared";
import { HomeBackground } from "shared/home-background";
import { ZB, phoneNumber } from "utils/consts";
import { Link } from "react-router-dom";

export const Nav: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeIfOpen = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const menuItems = (
    <>
      <a href="/#how-it-works" className="home-link" onClick={closeIfOpen}>
        Why{ZB}
      </a>
      <Link to="/studies" className="home-link">
        Studies
      </Link>
      <a href="/#book" className="home-link" onClick={closeIfOpen}>
        Contact{ZB}
      </a>
    </>
  );
  return (
    <header data-thq="thq-navbar" className="home-navbar">
      <Menu isOpen={isMenuOpen} closeModal={() => setIsMenuOpen(false)}>
        {menuItems}
      </Menu>

      <div className="home-left">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 10,
          }}
        >
          <Logo />
          <nav className="home-links-mobile">
            <span>
              <a className="home-text04" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </span>
          </nav>
        </div>
        <img
          className="mobile-menu pointer"
          src="/menu.png"
          height={50}
          onClick={() => setIsMenuOpen(true)}
        />
      </div>
      <div className="new-menu-items">{menuItems}</div>
    </header>
  );
};
