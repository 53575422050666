import React, { FC } from "react";
import { Link } from "react-router-dom";
import { EPA, Logo } from "shared";

export const FooterLeft: FC = () => {
  return (
    <div className="home-left1">
      <div className="home-brand">
        <Logo />
        <EPA />
      </div>
      {/* <div className="home-socials">
        <div className="social">
          <img alt="image" src="/Icons/insider.svg" className="home-image33" />
        </div>
        <div className="social">
          <img
            alt="image"
            src="/Icons/instagram.svg"
            className="home-image34"
          />
        </div>
        <div className="social">
          <img alt="image" src="/Icons/twitter.svg" className="home-image35" />
        </div>
      </div> */}
      <div className="home-legal">
        <span className="home-copyright">
          © {`${new Date().getFullYear()}`} Zero Bugs™. All Rights Reserved.
        </span>
      </div>
    </div>
  );
};
