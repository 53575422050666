import React, { FC } from "react";

export const HeroList: FC = () => {
  return (
    <ul>
      <li>
        <span className="capital">N</span>o <span className="capital">P</span>
        oisons | <span className="capital">N</span>o{" "}
        <span className="capital">N</span>eurotoxins{" "}
      </li>
      <li>
        <span className="capital">S</span>afest{" "}
        <span className="capital">A</span>round{" "}
        <span className="capital">F</span>amily{" "}
        <span className="capital">A</span>nd <span className="capital">P</span>
        ets
      </li>
      <li>
        <span className="capital">N</span>o <span className="capital">M</span>
        oving <span className="capital">O</span>ut{" "}
        <span className="capital">D</span>uring{" "}
        <span className="capital">T</span>reatment
      </li>
      <li>
        <span className="capital">N</span>o <span className="capital">T</span>
        enting <span className="capital">O</span>r{" "}
        <span className="capital">B</span>agging{" "}
        <span className="capital">Y</span>our <span className="capital">F</span>
        ood
      </li>
    </ul>
  );
};
