import React, { FC } from "react";

interface ButtonProps {
  text: string;
  type?: "submit" | "reset" | "button";
  processing?: boolean;
}

export const Button: FC<ButtonProps> = ({ text, type, processing = false }) => {
  return (
    <button className="button button-main home-android" type={type}>
      {processing ? <img src="/loader.gif" height={20} /> : <span>{text}</span>}
    </button>
  );
};
