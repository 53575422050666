import React, { FC } from "react";

import "./licensee.css";

interface LicenseeProps {
  objectFit?: any;
  heading?: string;
  text?: string;
  imageSrc: string;
}
export const Licensee: FC<LicenseeProps> = (props) => {
  const { heading, text, imageSrc, objectFit = "cover" } = props;
  const padding = objectFit === "cover" ? 0 : `0 10px`;

  return (
    <div className="doctor-doctor">
      <img
        alt={heading}
        src={imageSrc}
        className="doctor-image"
        height={300}
        width={100}
        style={{ objectFit, padding }}
      />
      <div className="doctor-heading">
        <h2 className="doctor-text">{heading}</h2>
        <p className="doctor-text1">{text}</p>
      </div>
    </div>
  );
};
