import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import "./style.css";
import "./vars.css";
import "./sections.css";

import { Home, Nav, NotFound, Studies } from "./views";
import { handleVisit } from "utils/fns";
import { getCodes } from "utils/services";
import CTA from "components/cta";
import { phoneNumber } from "utils/consts";

// Extend the String interface to include the tlc method
declare global {
  interface String {
    tlc(): string;
  }
}

String.prototype.tlc = function () {
  return this.toLowerCase();
};

const HR = () => {
  const location = useLocation();

  return location.pathname !== "/" ? <hr /> : null;
};

export interface ReferralProps {
  codes: string[];
}

const Referral: React.FC<ReferralProps> = ({ codes }) => {
  const codeFromPath = useLocation().pathname.replace("/", "").tlc();

  useEffect(() => {
    const runReferralCheck = async () => {
      // if no codes, then too early to check for visit
      if (!codes.length) {
        return null;
      }

      const codeExists = codes.includes(codeFromPath);

      // if random url, then no need to check for visit
      if (codeExists) {
        const localStorageCode = localStorage.getItem("code");

        if (localStorageCode) {
          if (codeFromPath === localStorageCode.tlc()) {
            return null;
          } else {
            await handleVisit(codeFromPath);
            localStorage.setItem("code", codeFromPath);
            return null;
          }
        }

        await handleVisit(codeFromPath);
        localStorage.setItem("code", codeFromPath);
      }
    };

    runReferralCheck();
  }, [codes, codeFromPath]);

  return null;
};

const App = () => {
  const [codes, setCodes] = React.useState<string[]>([]);
  useEffect(() => {
    const fetchCodes = async () => {
      const { codes } = await getCodes();
      if (!codes) return;

      const lowerCaseCodes = codes.map((code: string) => code.tlc());
      setCodes(lowerCaseCodes);
    };

    fetchCodes();
  }, []);

  return (
    <Router>
      {/* phone link */}
      <a href={`tel:${phoneNumber}`}>
        <CTA
          text="Get a FREE $450 Air Purifier when you use ZeroBugs®! Contact Us for details!"
          bgColor="#8cbd51"
        />
      </a>
      <div style={{ marginTop: 50 }}>
        <Nav />
        <HR />
        <Referral codes={codes} />
        <Switch>
          <Route render={() => <Home codes={codes} />} exact path="/" />
          <Route component={Studies} path="/studies" />
          <Route path="/:name" render={() => <Home codes={codes} />} />
          <Route component={NotFound} path="**" />
          <Redirect to="**" />
        </Switch>
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
