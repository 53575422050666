import { logVisit } from "utils/services";

export const handleVisit = async (code: string) => {
  try {
    await logVisit({ code });
    localStorage.setItem("code", code);
  } catch (error) {
    console.error(error);
  }
};
