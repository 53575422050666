import { Features } from "components";
import React, { FC } from "react";
import { zeroBugs } from "utils/consts";

export const FeatureList: FC = () => {
  return (
    <div id="features" className="home-features">
      <div className="home-content01">
        <Features
          description={`${zeroBugs} is not a Service Company, but a product and marketing company.`}
        />
        <Features
          description={`Our mission is two fold; A) to provide every pest service company with the safest insect elimination product with ZERO TOXINS, ZERO POISONS and NEUROTOXINS. B) to generate customers for those providing service of this revolutionary product.`}
        />
      </div>
    </div>
  );
};
