import React, { FC } from "react";
import { FooterLeft } from "./footer-left";
import { FooterRight } from "./footer-right";

export const Footer: FC = () => {
  return (
    <footer id="footer" className="home-footer">
      <FooterLeft />
      <FooterRight />
    </footer>
  );
};
