import React, { FC } from "react";
import { FooterNav } from "sections/footer/footer-nav";
import { address, email, phoneNumber } from "utils/consts";

const menuStrings = ["Home", "About", "Services", "Blog", "Support"];

export const FooterRight: FC = () => {
  const menuItems = menuStrings.map((str) => (
    <span key={str} className="home-link">
      {str}
    </span>
  ));

  return (
    <div className="home-right1">
      {/* <FooterNav title="Menu" items={menuItems} /> */}
      <div className="home-list5">
        <span className="home-header18">Contact</span>
        <div className="footer-links">
          <span className="home-link">{address}</span>
          <a href={`mailto:${email}?subject=Main`} className="home-link">
            {email}
          </a>
          <a href={`tel:${phoneNumber}`} className="home-link">
            {phoneNumber}
          </a>
        </div>
      </div>
    </div>
  );
};
