import React from "react";
import "./cta.css";

const CTA: React.FC<{ text: string; bgColor: string }> = ({
  text,
  bgColor,
}) => {
  return (
    <div className="cta" style={{ backgroundColor: bgColor }}>
      <p>{text}</p>
    </div>
  );
};

export default CTA;
