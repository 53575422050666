import React, { FC } from "react";
import "./menu.css";

interface MenuProps {
  isOpen: boolean;
  closeModal: () => void;
  children: JSX.Element;
}

export const Menu: FC<MenuProps> = ({ isOpen, children, closeModal }) => {
  const openClass = isOpen ? "open" : "close";

  return (
    <>
      <div className={`grey-overlay ${openClass}`} onClick={closeModal} />
      <div className={`menu-slideout ${openClass}`}>
        {children}{" "}
        <img
          className="pointer close-x"
          src="/close-x.svg"
          width={25}
          onClick={closeModal}
        />
      </div>
    </>
  );
};
