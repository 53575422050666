import React, { FC } from "react";

interface EPAProps {
  color?: "white" | "black";
}

export const EPA: FC<EPAProps> = ({ color = "white" }) => {
  return (
    <div className={`epa ${color}`}>
      <img src="/Branding/EPA.png" height={75} />
      <span>
        <p>United States EPA</p>
        <p>G.R.A.S. Certified</p>
      </span>
    </div>
  );
};
