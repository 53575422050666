import React, { FC } from "react";
import { ContactHeader } from "./contact-header";
import { Form } from "./form";
import { ReferralProps } from "index";

export const Contact: FC<ReferralProps> = ({ codes }) => {
  return (
    <>
      <Form codes={codes} />
    </>
  );
};
