import React, { FC } from "react";
import { Spacer } from "shared";
import "./studies.css";
import { EPAStudy } from "utils/consts";

export type Articles = "fourHorsemen";

export const Studies: FC = () => {
  return (
    <section id="privacy">
      <h1>The Four Horsemen</h1>
      <Spacer />
      <ul>
        <p>a. Carbamates</p>
        <p>b. Organochlorines</p>
        <p>c. Organophosphates</p>
        <p>
          d. Pymethrene (pyrethroids if lab manufactured, other wise derived
          from a flower)
        </p>
      </ul>
      <Spacer />
      <Spacer />
      <h2>Two points to consider:</h2>
      <h3>
        1.If a pesticide has an EPA registration number, it will on the label if
        there is one, it is poison .... or it isn't registered.
      </h3>
      <h3>
        2. Remember FIFRA 25(b) provides and exemption, that is EPA will not
        register anything it knows thatis not toxic.
      </h3>
      <Spacer />
      <h3>The compounds on the "25 List"</h3>
      <p>
        The compounds on the "25 List" are known to EPA to be Generally Regarded
        As Safe, GRAS. So what is and where is a definitive list found that
        lists of all the toxic poisonous pesticides that EPA registers, it's
        everything they register. EPA says this number is about 800. Several are
        registered under different brand names.
      </p>
      <Spacer />

      <Spacer />
      <h3>EPA Registration and Product Safety</h3>
      <p>
        A google search or a quick read of a product's SOS form will lead back
        to one of the 4 noted above. But remember the basic rule. If a product
        has an EPA registration it is poison!
      </p>
      <Spacer />

      <Spacer />
      <h3>EPA's Publication on Risk Management of Neurotoxins</h3>
      <p>
        <a
          href={EPAStudy}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "underline", color: "blue" }}
        >
          ATTACHED
        </a>{" "}
        is EPA's own publication on Risk Management of Neurotoxins. This list is
        a US government publication, it provides the known links between
        chemicals and the disability that is specific to the chemical. This fits
        exactly in with the idea of not criticizing any company or any brand but
        rather on the science that is intended to allow people to protect
        themselves and their families.
      </p>
      <Spacer />

      <Spacer />
      <h3>Independent Studies and State Registration Certificates</h3>
      <p>
        I'm sure you'll find a wealth of information that will make all the
        points you want to make and you'll have no less a source than the EPA to
        quote. At the same time, you'll have the EPA list of the Generally
        Regarded As Safe ingredients and you'll be able to show the EPA list
        compared to the label of the products you will be using. There is
        nothing in the products that is not on the EPA list.. ... so says 1. the
        independent study from the American Academy of Entomological Sciences
        (attached not previously provided to you) and 2. the registration
        Certificates from the states that actually demand test data for FIFRA
        25b products, namely, Oregon, Washington and New Mexico. (Previously
        provided.)
      </p>
      <Spacer />

      <Spacer />
      <h3>Contact Information and Conclusion</h3>
      <p>
        You are invited to call with any questions as you read the EPA's
        publication. We hope that criticism comes in a simple presentation of
        the facts. The idea is to share the advantages of 21st century science.
        Help people get to a safer place by helping them get into the 21st
        century.
      </p>
      <Spacer />
    </section>
  );
};
