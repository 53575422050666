import React from "react";

export const zeroBugs = "Zero Bugs™";
export const phoneNumber = "1-940-260-2847";
export const email = `gkarren@zerobugs.com`;
export const address = `568 S Oak Drive Woodland Hills, UT 84653`;
export const ZB = (
  <span>
    &nbsp;
    <span className="zb-initials brown">Z</span>
    <span className="zb-initials grey">B</span>
    &nbsp;
  </span>
);

export const EPAStudy = `https://docs.google.com/document/d/1HYHa9JnV2SfFPgZ567YXDj4Blulr07Iu/edit?usp=sharing&ouid=105304213258470483037&rtpof=true&sd=true`;
