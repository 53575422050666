import { Service } from "components";
import React, { FC } from "react";

export const Services: FC = () => {
  return (
    <section className="home-features1">
      <h1 className="services-header">
        NO NEED TO CONTAMINATE YOUR HOME WITH POISONS TO KILL THESE INSECTS
      </h1>
      <Service
        title="FLEA & BED BUG TREATMENT"
        description="Zero Bugs™ focused our experimental phase on bed bugs treatments to ensure that we have created an effective solution."
        image="fleas"
        imageSide="left"
      />
      <Service
        title="SPIDER CONTROL"
        description="If you continuously find yourself getting caught up in spider webs in or around your home, it's time to contact the experts for spider control services."
        image="spiders"
        imageSide="right"
      />
      <Service
        title="COCKROACH CONTROL"
        description="If you notice any cockroach activity in your home, it's important to call Zero Bugs™ Pest Control right away."
        image="roaches"
        imageSide="left"
      />
      <Service
        title="RODENT CONTROL"
        description="At Zero Bugs™ Pest Control, our experts are well-equipped to handle rodent control efficiently. "
        image="rodents"
        imageSide="right"
      />
      <Service
        title="MOSQUITO CONTROL"
        description="At Zero Bugs™ Pest Control, we employ advanced techniques and innovative approaches to target and eliminate mosquitoes at their source while keeping you and your family safe. "
        image="mosquitos"
        imageSide="left"
      />
      <Service
        title="GENERAL PEST INSPECTIONS"
        description="Our expert exterminators perform initial pest inspections before getting started on treatment in order to help you build a tailored plan for your home. "
        image="ants"
        imageSide="right"
      />
    </section>
  );
};
