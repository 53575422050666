import React, { FC } from "react";
import { EPA } from "shared";

interface GuaranteeProps {
  color?: "white" | "black";
}

export const Guarantee: FC<GuaranteeProps> = ({ color = "white" }) => {
  return (
    <div className="guarantee">
      <EPA color={color} />
    </div>
  );
};
