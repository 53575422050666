import React, { FC } from "react";

export const WhyZeroBugs: FC = () => {
  return (
    <section id="how-it-works" className="home-why">
      <div className="home-heading09">
        <h2 className="home-header08">Why Zero Bugs™</h2>
        <p className="home-header09">
          What sets Zero Bugs™ is their discovery of the use of a super protein
          which harnesses the power of nature. This unique process provides our
          product to deliver a “physical kill” - not a “toxic chemical kill.”
          This is a revolutionary science that is the safest way to exterminate
          bugs for humans and pets.
        </p>
      </div>
      <div className="home-content03">
        <div className="home-caption7">
          <h3 className="home-header10">But it doesn't stop there.</h3>
          <p className="home-header11">
            Zero Bugs™ technology continues to work for months, providing
            long-lasting protection against unwanted critters. No more constant
            reapplications or worrying about whether the problems will come
            back. Zero Bugs™ has got you covered.
          </p>
        </div>
      </div>
    </section>
  );
};
