import React, { FC } from "react";
import { FeatureList } from "./feature-list";
import { Hero } from "./hero";
import { HomeBackground } from "shared/home-background";

export const HomeHero: FC = () => {
  return (
    <section className="home-hero">
      <Hero />
      <FeatureList />
      <HomeBackground />
    </section>
  );
};
