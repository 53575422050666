import React, { FC } from "react";

interface HomeBackgroundProps {
  isNav?: boolean;
}

export const HomeBackground: FC<HomeBackgroundProps> = ({ isNav = false }) => {
  const heightClass = isNav ? "nav" : "full";
  return <div className={`home-background ${heightClass}`} />;
};
