import { Review } from "components";
import React, { FC } from "react";

interface ReviewsProps {
  id?: string;
}

export const Reviews: FC<ReviewsProps> = () => {
  return (
    <section className="home-practices1">
      <div className="home-heading08">
        <h2 className="home-text14">DON'T JUST TAKE OUR WORD FOR IT</h2>
      </div>
      <div className="home-content02">
        <div className="home-grid1">
          <div className="home-practice-wrapper">
            <Review
              name="Sarah Johnson"
              review="Zero Bugs is absolutely stellar! I loved their revolutionary ideas and eco-friendly products! I felt safe!"
              city="Irvine, TX"
            />
          </div>
          <div className="home-practice-wrapper">
            <Review
              name="James Coffey"
              review="The product worked WONDERS and quickly solved our hornet problem!"
              city="Austin, TX"
            />
          </div>
          <div className="home-practice-wrapper">
            <Review
              name="Ben Pulver"
              review="Our customers are thrilled with Zero Bugs product line, it has boosted our revenue by quite a bit."
              city="Dallas, TX"
            />
          </div>
          <div className="home-practice-wrapper">
            <Review
              name="Emily Rodriguez"
              review="Their top notch products and friendly staff make them the top choice for pests!"
              city="Dallas, TX"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
