import React, { FC } from "react";

import "./features.css";

interface FeatureProps {
  description: string;
}
export const Features: FC<FeatureProps> = (props) => {
  const { description } = props;

  return (
    <div className="features-section quick-links">
      <p className="features-text">{description}</p>
    </div>
  );
};
