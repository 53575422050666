import React, { FC } from 'react';

import './review.css';

interface ReviewProps {
	name: string;
	review: string;
	city: string;
}

export const Review: FC<ReviewProps> = (props) => {
	const { name, review, city } = props;

	return (
		<div className="practice-practice">
			<div className="practice-heading">
				<h3 className="practice-header">⭐️⭐️⭐️⭐️⭐️</h3>
				<p className="practice-caption">{review}</p>
			</div>
			<div className="read-more">
				<p className="practice-text">{name}</p>
			</div>
			<p>{city}</p>
		</div>
	);
};
